@mixin flexbox($direction: row, $justify: center, $align: center) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

@mixin respond-to($breakpoint) {
  @if $breakpoint == 'phone' {
    @media (max-width: 600px) and (min-height: 600px) {
      @content;
    }
  } @else if $breakpoint == 'phone-landscape' {
    @media (min-width: 600px) and (max-height: 600px) and (orientation: landscape) {
      @content;
    }
  } @else if $breakpoint == 'tablet' {
    @media ((min-width: 600px) and (max-width: 1024px)) {
      @content;
    }
  } @else if $breakpoint == 'desktop' {
    @media (min-width: 1025px) {
      @content;
    }
  }
}

// page container
@mixin page-container {
  @include flexbox(column, start, center);
  padding: 0;
  padding-top: var(--header-height);
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

@mixin page-container-content($maxWidth: var(--page-max-width-m)) {
  padding: 0;
  padding-top: var(--space);
  padding-bottom: var(--space-2x);
  width: 100%;
  max-width: $maxWidth;
  // height: 100%;
  // overflow-y: scroll;

  @include respond-to('phone') {
    padding: 12px 24px 24px 24px;
  }
  @include respond-to('tablet') {
    padding: 12px 24px 24px 24px;
  }
}

@mixin float-label {
  position: relative;

  &:hover > :global(.label) {
    opacity: 1;
  }
}

@mixin hide-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@mixin custom-scrollbar {
  // 滾動軌道
  &::-webkit-scrollbar-track {
    background-color: var(--background-secondary-color);
  }

  // 滾動條
  &::-webkit-scrollbar-thumb {
    background-color: var(--border-color);
  }
}

@mixin background-blur($background: var(--background-color-rgb)) {
  background: rgba($background, 0.75);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

@mixin border-radius($radius: 12px) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  -o-border-radius: $radius;
}

@mixin transition($transition) {
  transition: $transition;
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -ms-transition: $transition;
  -o-transition: $transition;
}

@mixin transform-container($transform) {
  transform: $transform;
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -ms-transform: $transform;
  -o-transform: $transform;
}

@mixin animation-container($animation) {
  animation: $animation;
  -webkit-animation: $animation;
  -moz-animation: $animation;
  -ms-animation: $animation;
  -o-animation: $animation;
}
